import classNames from 'classnames'
import type { FunctionComponent } from 'react'

import { Markdown } from '../../components/markdown'
import { StarRating } from '../../components/star-rating'
import variables from '../../styles/variables.module.scss'
import styles from './review-card.module.scss'

export const ReviewCard: FunctionComponent<ReviewCardProps> = ({
    title = '',
    titleColor = 'white',
    titleSizeDesktop = 'heading-lg',
    titleSizeMobile = 'heading-sm',
    text = '',
    textColor = 'white',
    textSizeDesktop = 'body-md',
    textSizeMobile = 'body-xs',
    author = '',
    authorSize = 'heading-xxs',
    rating = '',
    ratingColor = 'coal-mine-canary',
    backgroundColor = 'darkGray',
    className,
}) => {
    return (
        <div
            className={classNames(
                styles.card,
                styles.card,
                className,
                styles['scroll-card']
            )}
            style={{ backgroundColor: variables[backgroundColor] }}
        >
            <div className={styles['card-inner']}>
                <Markdown
                    markdown={title}
                    typeStyle={{ _: titleSizeMobile, md: titleSizeDesktop }}
                    color={titleColor}
                    boldColor={titleColor}
                    tag='h6'
                />
                <div className={styles['card-bottom']}>
                    {rating && (
                        <div className={styles['star-container']}>
                            <StarRating
                                starSize='large'
                                value={rating}
                                color={
                                    ratingColor !== undefined
                                        ? `step-${ratingColor}`
                                        : 'step-coal-mine-canary'
                                }
                            />
                        </div>
                    )}
                    <Markdown
                        markdown={text}
                        boldColor={textColor}
                        color={textColor}
                        typeStyle={{ _: textSizeMobile, md: textSizeDesktop }}
                        tag='div'
                    />
                    <div className={styles['card-name']}>
                        <Markdown
                            markdown={author}
                            boldColor={textColor}
                            color={textColor}
                            typeStyle={authorSize}
                            tag='div'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export interface ReviewCardData {
    title?: string
    titleColor?: string
    titleSizeDesktop?: string
    titleSizeMobile?: string
    text?: string
    textColor?: string
    textSizeDesktop?: string
    textSizeMobile?: string
    author?: string
    authorSize?: string
}

export interface ReviewCardProps extends ReviewCardData {
    index: number
    rating?: string | number
    ratingColor?: string
    className?: string
    backgroundColor?: string
}
